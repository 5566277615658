import { createSlice } from "@reduxjs/toolkit";
import AuthService from "../../auth/authService";
import { RootState } from "../../store";

type AuthState =  {
  accessToken: string | null;
}

const initialState:AuthState = {
  accessToken: AuthService.getAuthToken(),
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken(state, { payload }) {
      state.accessToken = payload;
    },
    clearToken(state) {
      state.accessToken = null;
    }
  }
})

export default authSlice.reducer;

export const getTokenSelector = (state: RootState) => state.auth.accessToken;


export const { setToken, clearToken } = authSlice.actions
