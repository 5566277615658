import React, { useMemo, useRef } from "react";
import {useAppSelector} from "../../hooks/useAppSelector/useAppSelector";
import styles from './modal-window.module.css'
import { getModalReportState } from "../../features/reports/reportsSlice";
import { dateTimeOptions } from "../task-list/task-list-table";

const ModalWindow = () => {
  const modalReportState = useAppSelector(getModalReportState);
  const probabilisticDate = new Date(modalReportState.dates?.Probabilistic)
  const minimalDuration = new Date(modalReportState.dates?.MinimalDuration)
  const maximalDuration = new Date(modalReportState.dates?.MaximalDuration)
  if (modalReportState.isOpen === false) {
    return null
  }
  return (
    <div
      className={styles.modalWindow}
      style={{left: `${modalReportState.positions.left}px`, top: `${modalReportState.positions.top - 70}px`}}
    >
      <p className={styles.text}>Probabilistic Date: {probabilisticDate.toLocaleDateString("en", dateTimeOptions) || ""}</p>
      <p className={styles.text}>Minimal Date: {minimalDuration.toLocaleDateString("en", dateTimeOptions) || ""}</p>
      <p className={styles.text}>Maximal Date: {maximalDuration.toLocaleDateString("en", dateTimeOptions) || ""}</p>
    </div>
  )
}

export default ModalWindow
