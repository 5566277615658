import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UrlService from "../../routes/utils/urlUtils";
import AuthService from "../../auth/authService";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import { setToken } from "../../features/auth/authSlice";

export const LoginPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const accessToken = UrlService.getIdTokenFromURL(location.hash);

    if (!accessToken) {
      AuthService.redirectToLogin();
      toast.error("There was an error trying to login. Please, try again");

      return;
    }

    // TODO collect report ID and path it as a param

    AuthService.setAuthToken(accessToken);
    dispatch(setToken(accessToken));
    navigate("/report");

    toast.success("You have been successfully logged in");
  }, [location, navigate, dispatch]);

  return <div></div>;
};