import { createSlice } from "@reduxjs/toolkit";
import {Task} from '../../types/public-types'
import { RootState } from "../../store";

export type TasksState = {
  data?: any,
  status: string
}

const initialState: TasksState = {
  data: [],
  status: 'idle'
}

export const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    setTasks(state, { payload }) {
      state.data = payload;
    },
  }
})

export default tasksSlice.reducer

export const getTasksSelector = (state: RootState) => state.tasks.data;

export const { setTasks } = tasksSlice.actions
