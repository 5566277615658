import tl1 from '../tl_1.json';
import tl500 from '../tl_500.json';
import tl1000 from '../tl_1000.json';
import testData from '../testData.json'

export const getDataByUrl = (url: string | null) => {
  // console.log(testData)
  if (!url) {
    return testData
    return tl1000;
  }

  if (url === '1') {
    return tl1;
  }

  if (url === '500') {
    return tl500
  }

  if (url === '1000') {
    return tl1000;
  }

  return tl1000;
}
