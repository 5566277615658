/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Drawer, FormControlLabel, Switch } from "@mui/material";
import * as styles from './styles';
import FeaturesForm from "../features-form/features-form";
import { ViewSwitcher } from "../view-switcher/view-switcher";
import { getShowTaskListSelector, setShowTaskList } from "../../features/reports/reportsSlice";
import { useAppSelector } from "../../hooks/useAppSelector/useAppSelector";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";

type Props = {
  drawerOpened: boolean;
  onClose: () => void;
};

export const AppDrawer = ({
  drawerOpened, onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const showTaskList = useAppSelector(getShowTaskListSelector);

  const handleChangeTaskList = () => {
    dispatch(setShowTaskList(!showTaskList));
  }

  return (
    <Drawer
      onClose={onClose}
      open={drawerOpened}
      anchor="left"
    >
      <Box margin="normal" sx={styles.drawerSx}>
        <ViewSwitcher />
        <FeaturesForm />
        <FormControlLabel
          sx={styles.switcherContainerSx}
          control={<Switch
            onClick={handleChangeTaskList}
            defaultChecked
          />
        }
          label="Show task list"
        />
      </Box>
    </Drawer>
  );
};

export default AppDrawer;
