import React, { useContext } from "react";
import { taskXCoordinate } from "../../../helpers/bar-helper";
import jsonData from '../../../tl_1.json';
import { FeaturesFormContext } from "../../features-form/context/featuresFormContext";
import { useAppSelector } from "../../../hooks/useAppSelector/useAppSelector";
import {
  getFormStateSelector,
  setModalReport,
  getModalReportState,
  setModalReportClose,
} from "../../../features/reports/reportsSlice";
import {useAppDispatch} from "../../../hooks/useDispatch/useAppDispatch";
import { useSearchParams } from "react-router-dom";
import { getDataByUrl } from "../../../utils/getDataByUrl";
import { getTasksSelector } from "../../../features/tasks/tasksSlice";
import { Task } from "../../../types/public-types";
// import { getColorTones } from "../../../helpers/other-helper";

type BarDisplayProps = {
  id: number;
  y: number;
  height: number;
  columnWidth: number;
  barStrokeColor: string;
  barFillBaseRBGColor: string;
  styles: {
    backgroundColor: string;
    backgroundSelectedColor: string;
    progressColor: string;
    progressSelectedColor: string;
  };
  days: {
    startDates: string[];
    endDates: string[];
    start: Date;
    end: Date;
  };
  dates: Date[];
  opacity: number;
  isLast?: boolean;
};

export const BarDisplay: React.FC<BarDisplayProps> = ({
  id,
  y,
  height,
  columnWidth,
  // in the future the styles prop should be used for coloring
  // styles,
  dates,
  days,
  barFillBaseRBGColor,
  barStrokeColor,
  opacity,
  isLast
}) => {
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch()
  const tasksSelector = useAppSelector(getTasksSelector)
  const dataSizeString = searchParams.get('dataSize');
  // in the future transfer this data via context
  const lines = tasksSelector.LinesOnCriticalPath
    .map((task: any) => ({...task, TaskID: +task.TaskID}))
    .sort((a: any, b: any) => +a.TaskID - +b.TaskID);

  // in the future if needed to use several tones
  // const colors = getColorTones("rgba(242, 158, 76)", 10)
  const color = `rgba(${barFillBaseRBGColor}, ${opacity})`;

  // COLORS HERE

  const dottedX = taskXCoordinate((days.start), dates, columnWidth);
  const dotted = {
    x: dottedX,
    width: taskXCoordinate(new Date(days.end), dates, columnWidth) - dottedX,
  }

  const coordinates = days.startDates.map((_, i) => {
    const sDate = new Date(days.startDates[i]);
    const eDate = new Date(days.endDates[i]);

    if (!eDate) {
      console.log(days.endDates);
    }

    if (!sDate) {
      console.log(days.startDates);
    }
    const x = taskXCoordinate(new Date(days.startDates[i]), dates, columnWidth);


    const width = taskXCoordinate(new Date(days.endDates[i]), dates, columnWidth) - x; // HERE IS BROKEN

    return {
      x,
      width ,
    }
  })

  const featuresState = useAppSelector(getFormStateSelector);
  const modalState = useAppSelector(getModalReportState);
  const isCriticalPath = lines.some((item: any) => item.TaskID === id);
  const shouldRenderCriticalPath = (isCriticalPath && featuresState.isCriticalPathEnabled);

  const toggleModal = (e: React.MouseEvent<SVGGElement, MouseEvent>) => {
    if ((id + 1) === tasksSelector.LinesOnCriticalPath.length && modalState.isOpen === false) {
      const {MaximalDuration, MinimalDuration, Probabilistic, TaskID} = tasksSelector.LinesOnCriticalPath[id]
      const dates = {TaskID, MaximalDuration, MinimalDuration, Probabilistic}
      dispatch(setModalReport({isOpen: true, positions: {left: e.pageX, top: e.pageY}, dates}))
    } else if ((id + 1) === tasksSelector.LinesOnCriticalPath.length && modalState.isOpen === true) {
      dispatch(setModalReportClose())
    }
  }

  return (
    <g onClick={toggleModal}>
      {
        coordinates.map((coord, i) => (
          <rect
            key={i}
            x={coord.x}
            width={coord.width}
            y={y}
            height={height}
            fill={color}
            stroke={barStrokeColor}
          />
        ))
      }

      {/* Dotted chart */}
      <rect
        x={dotted.x}
        width={dotted.width}
        y={y}
        height={height}
        fill={'none'}
        stroke={barStrokeColor}
        strokeDasharray={'0 5 0'}
      />

      {shouldRenderCriticalPath ? (
        <path
          strokeWidth="1.5"
          d={`M ${dotted.x} ${y + height} L ${dotted.x + dotted.width} ${y + height}`}
          fill="none"
          stroke="#e6151f"
        />
      ) : null}
    </g>
  );
};
