import React, { useState } from "react";
import MUIAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AppDrawer from "../app-drawer";
import { useIsLoggedIn } from "../../auth/hooks/useIsLoggedIn";
import AuthService from "../../auth/authService";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import { clearToken } from "../../features/auth/authSlice";

export const AppBar = () => {
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const isLoggedIn = useIsLoggedIn();

  const handleMenuClick = () => setDrawerOpened(true);

  const handleDrawerClose = () => setDrawerOpened(false);

  const handleLoginClick = () => {
    AuthService.redirectToLogin();
  }

  const handleLogoutClick = () => {
    AuthService.clearAuthToken();
    dispatch(clearToken());
  }

  const onAuthButtonClick = isLoggedIn ? handleLogoutClick : handleLoginClick;
  const authButtonLabel = isLoggedIn ? 'Logout' : 'Login';

  return (
      <MUIAppBar position="sticky">
        <Toolbar>
          <IconButton
            onClick={handleMenuClick}
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Oreo
          </Typography>
          <Button onClick={onAuthButtonClick} color="inherit">{authButtonLabel}</Button>
        </Toolbar>
        <AppDrawer drawerOpened={drawerOpened} onClose={handleDrawerClose} />
      </MUIAppBar>
  )
}