import React from "react";
import { ReactElement } from "react";
import ReportPage from "../pages/report-page/report-page";
import PageNotFound from "../pages/page-not-found/page-not-found";
import { LoginPage } from "../pages/login-page/login-page";

type AppRoute = {
  path: string;
  element: ReactElement;
}

export const appRoutes: AppRoute[] =
  [
    {
      path: "/report",
      element: <ReportPage />
    },
    {
      path: "/",
      element: <ReportPage />
    },
    {
      path: "/login",
      element: <LoginPage />
    },
    {
      path: "*",
      element: <PageNotFound />
    }
  ]