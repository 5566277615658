import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import reportsReducer from './features/reports/reportsSlice'
import authReducer from './features/auth/authSlice';
import tasksReducer from './features/tasks/tasksSlice'

export const reducer = {
  reports: reportsReducer,
  auth: authReducer,
  tasks: tasksReducer
};

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    .concat([ // TODO add API
    ]),
});

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
ReturnType,
RootState,
unknown,
Action<string>
>;
