import React from "react";
import { ViewMode } from "../../types/public-types";
import * as styles from './styles';
import { Box, ButtonGroup, FormGroup, Switch } from "@mui/material";
import Button from "@mui/material/Button";
import { useAppSelector } from "../../hooks/useAppSelector/useAppSelector";
import {
  getShowTaskListSelector,
  getViewModeSelector,
  setShowTaskList,
  setViewMode,
} from "../../features/reports/reportsSlice";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import Typography from "@mui/material/Typography";

export const ViewSwitcher = () => {
  const viewMode = useAppSelector(getViewModeSelector);
  const dispatch = useAppDispatch();

  const handleViewModeChange = (viewMode: ViewMode) => () => {
    dispatch(setViewMode(viewMode));
  }

  return (
    <Box>
      <FormGroup>
        <Typography sx={styles.headerSx} variant="h6">Select view mode</Typography>
        <ButtonGroup sx={styles.switcherContainerSx} variant="contained" aria-label="outlined primary button group">
          <Button onClick={handleViewModeChange(ViewMode.Day)} color={viewMode === ViewMode.Day ? "secondary" : "primary"}>Day</Button>
          <Button onClick={handleViewModeChange(ViewMode.Month)} color={viewMode === ViewMode.Month ? "secondary" : "primary"}>Month</Button>
          <Button onClick={handleViewModeChange(ViewMode.Year)} color={viewMode === ViewMode.Year ? "secondary" : "primary"}>Year</Button>
        </ButtonGroup>
      </FormGroup>
    </Box>
  );
};
