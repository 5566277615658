import React, {FC} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

interface TableViewProps {
  data: any
}


const TableView: FC<TableViewProps> = ({data}) => {
  if (data === null) return null

  const names = Object.keys(data.Project as any)
  const cells = Object.values(data.Project as any)
  names.unshift('project')
  const cellsNames = Object.keys(cells[0] as any)

  const assigneeData = (JSON.parse(data.Assignee));
  const roleData = (JSON.parse(data.Role))
  console.log(assigneeData)

  const TableViewBody: FC<TableViewProps> = ({data}) => (
    <TableContainer component={Paper}>
      <Table
        sx={{
          [`& .${tableCellClasses.root}`]: {
            border: '1.5px solid black'
          }
        }}
      >
        <TableHead>
          <TableRow  style={{padding: 2}}>
            {data.Assignee &&  <TableCell style={{padding: 5}}>Assignee</TableCell>}
            <TableCell style={{padding: 5}}>Role</TableCell>
            <TableCell style={{padding: 5}}>Hours (Min)</TableCell>
            <TableCell style={{padding: 5}}>Hours (Max)</TableCell>
            <TableCell style={{padding: 5}}>Hours (Probabilistic)</TableCell>
            <TableCell style={{padding: 5}}>Costs (Min)</TableCell>
            <TableCell style={{padding: 5}}>Costs (Max)</TableCell>
            <TableCell style={{padding: 5}}>Costs (Probabilistic)</TableCell>
            <TableCell style={{padding: 5}}>Rates</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data['Role']).map((key) => (
            <TableRow key={key}>
              {data.Assignee &&  <TableCell style={{padding: 5}}>{data['Assignee'][key]}</TableCell>}
              <TableCell style={{padding: 5}}>{data['Role'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Hours_min'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Hours_max'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Hours_probabilistic'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Costs_min'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Costs_max'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Costs_probabilistic'][key]}</TableCell>
              <TableCell style={{padding: 5}}>{data['Rates'][key]}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
  return (
    <>
      <div style={{width: '25%', padding: 10}}>
        <h2>Project</h2>
        <TableContainer component={Paper}>
          <Table sx={{
            [`& .${tableCellClasses.root}`]: {
              border: '1.5px solid black'
            }
          }}
          >
            <TableHead>
              <TableRow>
                {names.map(item => <TableCell style={{padding: 5}}>{item}</TableCell>)}
              </TableRow>
            </TableHead>
            <TableBody>
              {cellsNames.map(item => (
                <TableRow
                  key={item}
                >
                  <TableCell style={{padding: 5}} align="left">{item}</TableCell>
                  <TableCell style={{padding: 5}} align="left">{(cells[0] as any)[item]}</TableCell>
                  <TableCell style={{padding: 5}} align="left">{(cells[1] as any)[item]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div style={{width: '80%', padding: 10}}>
        <h2>Assignee</h2>
        <TableViewBody data={assigneeData} />
      </div>
      <div style={{width: '75%', padding: 10}}>
        <h2>Role</h2>
        <TableViewBody data={roleData} />
      </div>
    </>
  )
}

export default TableView;
