import React, { Component, ErrorInfo, ReactNode } from "react";


type State = {
  hasError: boolean;
}

type Props = {
  children: ReactNode;
}

class ErrorBoundary extends Component<Props, State> {
  state = {
    hasError: false,
  }

  static getDerivedStateFromError() {
    // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      // Можно отрендерить запасной UI произвольного вида
      return <h1>Ooops, something went wrong</h1>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;