import React, { ChangeEvent } from "react";
import { Checkbox as MUICheckbox, FormControlLabel } from "@mui/material";

type CheckboxProps = {
  checked?: boolean;
  label: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  id: string;
};

const Checkbox = ({ label, checked = false, id, onChange }: CheckboxProps) => {
  return (
    <FormControlLabel
      control={<MUICheckbox defaultChecked={checked}
                            onChange={onChange}
                            id={id}/>}
      label={label} />
  );
};

export default Checkbox;
