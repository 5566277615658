import { createSlice } from "@reduxjs/toolkit";
import { FeaturesState } from "../../components/features-form/types";
import { RootState } from "../../store";
import { ViewMode } from "../../types/public-types";
import {BarLine} from "../../components/other/line";

const featuresInitialState: FeaturesState = {
  isTasksEnabled: true,
  isCriticalPathEnabled: false,
  isLinesEnabled: false,
  isWorkloadEnabled: true,
  isAnomaliesEnabled: true,
  isRisksEnabled: true,
};

export type ModalReportState = {
  isOpen: boolean
  positions: {left: number, top: number}
  dates: BarLine
}

export type IDrawerInitialState = {
  featuresState: FeaturesState,
  viewMode: ViewMode,
  showTaskList: boolean,
  modalReportState: ModalReportState
}

const modalReportInitialState: ModalReportState = {
  isOpen: false,
  positions: {left: 0, top: 0},
  dates: {
    TaskID: 0,
    MaximalDuration: '',
    MinimalDuration: '',
    Probabilistic: ''
  }
}

const initialState: IDrawerInitialState = {
  featuresState: featuresInitialState,
  viewMode: ViewMode.Day,
  modalReportState: modalReportInitialState,
  showTaskList: true,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setFormState(state, { payload }) {
      state.featuresState = payload;
    },
    setViewMode(state, { payload }) {
      state.viewMode = payload;
    },
    setShowTaskList(state, { payload }) {
      state.showTaskList = payload;
    },
    setModalReport(state, { payload }) {
      state.modalReportState = {
        isOpen: payload.isOpen,
        positions: payload.positions,
        dates: payload.dates
      }
    },
    setModalReportClose(state) {
      state.modalReportState = {
        ...state.modalReportState,
        isOpen: false
      }
    }
    // setModalReportDates(state, { payload }) {
    //   state.modalReportState = {
    //     ...state.modalReportState,
    //     dates: payload
    //   }
    // }
  },
});

export const getFormStateSelector = (state: RootState) => state.reports.featuresState;
export const getViewModeSelector = (state: RootState) => state.reports.viewMode;
export const getShowTaskListSelector = (state: RootState) => state.reports.showTaskList
export const getModalReportState = (state: RootState) => state.reports.modalReportState

export default reportsSlice.reducer;
export const { setFormState, setViewMode, setShowTaskList, setModalReport, setModalReportClose } = reportsSlice.actions;
