import queryString from "query-string";

const getIdTokenFromURL = (hash: string):string => {
  const parsedParams = queryString.parse(hash);

  return parsedParams.id_token as string;
}

export const UrlService = {
  getIdTokenFromURL,
}

export default UrlService;