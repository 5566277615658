import React from "react";
import "./index.css";
import "react-toastify/dist/ReactToastify.css";
import { Route, Routes } from "react-router-dom";
import { appRoutes } from "./routes/appRoutes";
import ErrorBoundary from "./components/error-boundary/error-boundary";
import { ToastContainer } from "react-toastify";

//MUI imports
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { AppBar } from "./components/app-bar/app-bar";
import { CssBaseline } from "@mui/material";

const App = () => {
  return (
    <ErrorBoundary>
      <CssBaseline />
      <AppBar />
      <Routes>
        {appRoutes.map(({element, path}) => {
          return <Route element={element} path={path}></Route>
        })}
      </Routes>
      <ToastContainer position="bottom-left" />
    </ErrorBoundary>
  );
};

export default App;
