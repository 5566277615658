import React, { ChangeEvent } from "react";
import Checkbox from "../checkbox/checkbox";
import { Feature } from "./types";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector/useAppSelector";
import { getFormStateSelector, setFormState } from "../../features/reports/reportsSlice";
import { FormGroup } from "@mui/material";
import Typography from "@mui/material/Typography";

export const FeaturesForm = () => {
  const dispatch = useAppDispatch();
  const featuresFormState = useAppSelector(getFormStateSelector);

  const features: Feature[] = [
    {
      label: "Critical path",
      checked: featuresFormState.isCriticalPathEnabled,
      id: "criticalPath",
    },
    {
      label: "Lines",
      checked: featuresFormState.isLinesEnabled,
      id: "lines",
    },
    /*{
      label: "Tasks",
      checked: true,
      id: "tasks",
    },
    {
      label: "Work load",
      checked: true,
      id: "workload",
    },
    {
      label: "Anomalies",
      checked: true,
      id: "anomalies",
    },
    {
      label: "Risks",
      checked: true,
      id: "risks",
    },*/

    // Will be added after features implementation
  ];

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fieldName = `is${e.target.id.charAt(0).toUpperCase() + e.target.id.slice(1)}Enabled`;
    const formState = {
      ...featuresFormState,
      [fieldName]: e.target.checked,
    }

    dispatch(setFormState(formState));
  }

  return (
    <FormGroup>
      <Typography variant="h6">Select features</Typography>
      {features.map(({ id, label, checked }) => {
        return (
          <Checkbox
            onChange={handleChange}
            id={id}
            key={id}
            label={label}
            checked={checked}
          />
        );
      })}
    </FormGroup>
  );
};

export default FeaturesForm;
