import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend, PointElement, LineElement, LineController, BarController,
} from "chart.js";
import { Bar, Chart } from 'react-chartjs-2';
import style from './Statistics.module.css'
// import statistics from '../../statistics.json'
import budget from '../../budget.json'

const Statistics = (statistics: any) => {
  if (statistics.length === 0) return null

  const dataArr = Object.values(statistics.statistics)

  const chartData = dataArr.map((item: any) => item.probability)
  const lineData = dataArr.map((item: any) => item.commulative_probability)

  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
  );

  const data = {
    labels: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'],
    datasets: [
      {
        type: 'line' as const,
        label: 'Cumulative probability',
        borderColor: 'rgb(245,184,30)',
        borderWidth: 2,
        fill: false,
        data: lineData,
      },
      {
        type: 'bar' as const,
        label: 'Probability',
        data: chartData,
        backgroundColor: 'rgba(8,73,193,0.5)',
        borderColor: 'blue',
        borderWidth: 1
      },
    ],
  };

  return (
    <div className={style.box}>
      <Chart data={data} type='bar'/>
    </div>
  )
}

export default Statistics
