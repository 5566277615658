import React from "react";
import { taskXCoordinate } from "../../helpers/bar-helper";
import { BarTask } from "../../types/bar-task";
import { createDateObject } from "../../hooks/useGetChartData/useChartData";

export interface BarLine {
  TaskID: number;
  MinimalDuration: string;
  Probabilistic: string;
  MaximalDuration: string;
}

type LineProps = {
  lineFrom: BarLine;
  lineTo: BarLine;
  taskHeight: number;
  dates: Date[];
  columnWidth: number;
  tasks: BarTask[];
};

export const Line: React.FC<LineProps> = ({
  lineFrom,
  lineTo,
  taskHeight,
  dates,
  columnWidth,
  tasks,
}) => {
  // const dispatch = useAppDispatch()
  if (tasks.length === 0) return null;

  const [taskFrom] = tasks.filter(task => task.id === lineFrom.TaskID);
  const [taskTo] = tasks.filter(task => task.id === lineTo.TaskID)
  // if (taskTo.isLast) {
  //   dispatch(setModalReportDates(lineTo))
  // }

  if (taskFrom === undefined || taskTo === undefined) return null

  const minX1 = taskXCoordinate(createDateObject(lineFrom.MinimalDuration), dates, columnWidth);
  const minX2 = taskXCoordinate(createDateObject(lineTo.MinimalDuration), dates, columnWidth);
  const minDurPath = `M ${minX1} ${taskFrom.y + taskHeight / 2} L ${minX2} ${taskTo.y + taskHeight / 2}`;

  const maxX1 = taskXCoordinate(createDateObject(taskFrom.start as unknown as string), dates, columnWidth);
  const maxX2 = taskXCoordinate(createDateObject(taskTo.end as unknown as string), dates, columnWidth);
  const maxDurPath = `M ${maxX1} ${taskFrom.y + taskHeight / 2} L ${maxX2} ${taskTo.y + taskHeight / 2}`;

  //console.log({lineFrom, lineTo, taskFrom, taskTo, maxX1, maxX2, maxDurPath})

  const probX1 = taskXCoordinate(createDateObject(lineFrom.Probabilistic), dates, columnWidth);
  const probX2 = taskXCoordinate(createDateObject(lineTo.Probabilistic), dates, columnWidth);
  const probPath = `M ${probX1} ${taskFrom.y + taskHeight / 2} L ${probX2} ${taskTo.y + taskHeight / 2}`;

  return (
    <g className="line">
      <g className="minimal-duration">
        <path strokeWidth="1" d={minDurPath} fill="none" stroke="red" />
      </g>

      <g className="maximal-duration">
        <path strokeWidth="1" d={maxDurPath} fill="none" stroke="blue" />
      </g>

      <g className="probabilistic">
        <path strokeWidth="1" d={probPath} fill="none" stroke="green" />
      </g>
    </g>
  );
};
