import React from "react";
import { BarDisplay } from "./bar-display";
import { TaskItemProps } from "../task-item";
import styles from "./bar.module.css";

export const Bar: React.FC<TaskItemProps> = ({
  task,
  dates,
  columnWidth,
  barStrokeColor,
  barFillBaseRBGColor,
}) => {
  const opacity = 0.1;

  return (
    <g className={styles.barWrapper} tabIndex={0}>
      <BarDisplay
        opacity={opacity}
        id={task.id}
        y={task.y}
        columnWidth={columnWidth}
        height={task.height}
        styles={task.styles}
        days={task.days}
        dates={dates}
        barStrokeColor={barStrokeColor}
        barFillBaseRBGColor={barFillBaseRBGColor}
        isLast={task.isLast}
      />
    </g>
  );
};
