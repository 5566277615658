import React, { memo } from "react";
import { EventOption } from "../../types/public-types";
import { BarTask } from "../../types/bar-task";
import { Arrow } from "../other/arrow";
import { TaskItem } from "../task-item/task-item";
import { GanttEvent } from "../../types/gantt-task-actions";
import { Line } from "../other/line";
import { useAppSelector } from "../../hooks/useAppSelector/useAppSelector";
import { getFormStateSelector } from "../../features/reports/reportsSlice";
import { useSearchParams } from "react-router-dom";
import { getDataByUrl } from "../../utils/getDataByUrl";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import { getTasksSelector } from "../../features/tasks/tasksSlice";

export type TaskGanttContentProps = {
  tasks: BarTask[];
  dates: Date[];
  ganttEvent: GanttEvent;
  rowHeight: number;
  columnWidth: number;
  timeStep: number;
  svg?: React.RefObject<SVGSVGElement>;
  svgWidth: number;
  taskHeight: number;
  arrowColor: string;
  arrowIndent: number;
  fontSize: string;
  fontFamily: string;
  rtl: boolean;
  setGanttEvent: (value: GanttEvent) => void;
  setFailedTask: (value: BarTask | null) => void;
  barFillBaseRBGColor: string;
  barStrokeColor: string;
} & EventOption;

export const TGC: React.FC<TaskGanttContentProps> = ({
  tasks,
  dates,
  rowHeight,
  columnWidth,
  taskHeight,
  arrowColor,
  arrowIndent,
  fontFamily,
  fontSize,
  rtl,
  barStrokeColor,
  barFillBaseRBGColor,
}) => {
  const [searchParams] = useSearchParams();
  const dataSizeString = searchParams.get('dataSize');
  const json = getDataByUrl(dataSizeString);
  const tasksSelector = useAppSelector(getTasksSelector)

  // in the future transfer this data via context
  const lines = tasksSelector.LinesOnCriticalPath
    .map((task: any) => ({...task, TaskID: +task.TaskID}))
    .sort((a: any, b: any) => +a.TaskID - +b.TaskID);

  const featuresState = useAppSelector(getFormStateSelector);

  return (
    <g className="content">
      <g className="arrows" fill={arrowColor} stroke={arrowColor}>
        {tasks.map(task => {
          return task.barChildren.map(child => {
            return (
              <Arrow
                key={`Arrow from ${task.id} to ${tasks[child.index].id}`}
                taskFrom={task}
                taskTo={tasks[child.index]}
                rowHeight={rowHeight}
                taskHeight={taskHeight}
                arrowIndent={arrowIndent}
                rtl={rtl}
              />
            );
          });
        })}
      </g>

      {featuresState.isLinesEnabled
        && <g className="lines" fill={arrowColor} stroke={arrowColor}>
          {lines.map((line: any, i: number) => {
            if (i + 1 === lines.length) return [];

            return (
              <Line
                key={line.TaskID}
                lineFrom={line}
                lineTo={lines[i + 1]}
                taskHeight={taskHeight}
                dates={dates}
                columnWidth={columnWidth}
                tasks={tasks}
              />
            );
          })}
        </g>
      }

      <g className="bar" fontFamily={fontFamily} fontSize={fontSize}>
        {tasks.map(task => {
          return (
            <TaskItem
              barFillBaseRBGColor={barFillBaseRBGColor}
              barStrokeColor={barStrokeColor}
              task={task}
              arrowIndent={arrowIndent}
              taskHeight={taskHeight}
              isDelete={!task.isDisabled}
              key={task.id}
              rtl={rtl}
              dates={dates}
              columnWidth={columnWidth}
            />
          );
        })
        }
      </g>
    </g>
  );
};

export const TaskGanttContent = memo(TGC)

