import { isDev } from "../utils/enviroment";

const REDIRECT_URL_DEV = "http://localhost:3000/login";
const REDIRECT_URL_PROD = "https://dev.dataart-oreo.com/";

export const REDIRECT_URL =
   isDev ? REDIRECT_URL_DEV : REDIRECT_URL_PROD;

export const CLIENT_ID = "2ig95jmbvqs8pl1d6sj4h5n847";
export const signInUrl
  = `https://sign-in-2ig95jmbvqs8pl1d6sj4h5n847.auth.us-east-1.amazoncognito.com/login?response_type=token&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URL}`
