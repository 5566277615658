import { signInUrl } from "./constants";

const TOKEN_PATH = '@auth/token';

const setAuthToken = (authToken: string) => {
  localStorage.setItem(TOKEN_PATH, authToken);
};

const clearAuthToken = () => {
  localStorage.removeItem(TOKEN_PATH);
}

const getAuthToken = () => localStorage.getItem(TOKEN_PATH);

const isLoggedIn = () => !!getAuthToken();

const redirectToLogin = () => {
  window.location.replace(signInUrl)
}

const AuthService = {
  setAuthToken,
  getAuthToken,
  isLoggedIn,
  redirectToLogin,
  clearAuthToken,
}
export default AuthService;