import React, {useState, useEffect} from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { Gantt } from "../../components/gantt/gantt";
import { createDateObject, useChartData } from "../../hooks/useGetChartData/useChartData";
import { getGanttUIConfig } from "../../types/ganttUIConfig";
import { useAppSelector } from "../../hooks/useAppSelector/useAppSelector";
import { getShowTaskListSelector, getViewModeSelector } from "../../features/reports/reportsSlice";
import { Task, TaskType } from "../../types/public-types";
import ModalWindow from "../../components/modal/modal-window";
import { useAppDispatch } from "../../hooks/useDispatch/useAppDispatch";
import { $api } from "../../api/api";
import { setTasks } from "../../features/tasks/tasksSlice";
import Statistics from "../../components/statistics/Statistics";
import { getTokenSelector, setToken } from "../../features/auth/authSlice";
import UrlService from "../../routes/utils/urlUtils";
import AuthService from "../../auth/authService";
import { toast } from "react-toastify";
import TableView from "../../components/table/table";

export const ReportPage = () => {
  const showTasks = useAppSelector(getShowTaskListSelector);
  const viewMode = useAppSelector(getViewModeSelector);
  const uiConfig = getGanttUIConfig(viewMode);
  // const { tasks } = useChartData();
  const [tasks, setTask] = useState([])
  const [statistics, setStatistics] = useState([])
  const [budget, setBudget] = useState(null)
  const [searchParams] = useSearchParams()
  const token = useAppSelector(getTokenSelector)
  const location = useLocation();

  // const searchingTasks = (tasks: any) => setTask(tasks)

  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useAppDispatch()

  const accessToken = UrlService.getIdTokenFromURL(location.hash);

  const getData = (charts: any) => {
    const tasks = charts.map((task: any, i: number) => {
      const start = createDateObject(task.minimalStartDate);
      const end = createDateObject(task.maximalEndDate);
      const dependencies = task.parentIDs.map((parentId: number) => +parentId);


      return {
        start,
        end,
        name: task.taskName,
        id: +task.taskID,
        dependencies,
        type: "task" as TaskType,
        displayOrder: i + 1,
        isDisabled: true,
        days: {
          startDates: task.startDates,
          endDates: task.endDates,
          start: createDateObject(task.minimalStartDate),
          end: createDateObject(task.maximalEndDate),
          uniqueStartDatesCount: new Set(task.startDates),
        }
      }
    });

    const sortedTasks = tasks.sort((a: Task, b: Task) => a.id - b.id);
    setTask(sortedTasks)
  };

  const getServerData = async (reportName: string) => {
    setIsLoading(true)
    try {
      const data = await $api.get<any>(
        `reports?projectId=${searchParams.get('projectId')}&executionId=${searchParams.get('executionId')}&reportNames=${reportName}`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
      if (reportName === 'Gantt') {
        const charts = data.data[0].ReportContent.Chart[0].chartTasks

        dispatch(setTasks(data.data[0].ReportContent))
        getData(charts)
        setIsLoading(false)
      } else if (reportName === 'Statistics') {
        setStatistics(data.data[0].ReportContent)
      } else if (reportName === 'Budget') {
        setBudget(data.data[0].ReportContent)
      }
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  const getStatusPollInterval = () => {
    setIsLoading(true)
    const getReportStatus = setInterval(async () => {
      await request()
    }, 30000)

    setTimeout(() => {
      clearInterval(getReportStatus)
    }, 500000)

    const request = async () => {
      const data = await $api.get<any>(
        `reports/status?projectId=${searchParams.get('projectId')}&executionId=${searchParams.get('executionId')}&reportNames=Gantt`,
        {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        }
      )
      if (data.data[0].ReportStatus[0] === 'Created' && tasks.length === 0) {
        getServerData(data.data[0].ReportName)
      }

      if (data.data[1].ReportStatus[0] === 'Created') {
        getServerData(data.data[1].ReportName)
      }
      if (data.data[2].ReportStatus[0] === 'Created') {
        getServerData(data.data[2].ReportName)
        clearInterval(getReportStatus)
      }
    }

    request()

  }

  useEffect(() => {
    // getServerData()
    // getReportStatus()
    getStatusPollInterval()

    if (accessToken) {
      AuthService.setAuthToken(accessToken);
      dispatch(setToken(accessToken));

      toast.success("You have been successfully logged in");
    }
  }, []);


  if (isLoading) return <h1>Loading...</h1>

  return <div className="Wrapper">
    <ModalWindow />
    <Statistics statistics={statistics} />
    <TableView data={budget} />
    {!!tasks.length && (
      <>
        <h2>Gantt Chart</h2>
        <Gantt
          tasks={tasks}
          viewMode={viewMode}
          listCellWidth={showTasks ? "155px" : ""}
          {...uiConfig}
        />
      </>
    )}
  </div>;
};

export default ReportPage;
