import React, {useCallback} from "react";
import jsonData from "../../tl_1.json";
import { Task, TaskType } from "../../types/public-types";
import { useSearchParams } from "react-router-dom";
import { getDataByUrl } from "../../utils/getDataByUrl";
import { $api } from "../../api/api";
import { useAppSelector } from "../useAppSelector/useAppSelector";
import { getTokenSelector } from "../../features/auth/authSlice";

function localizeDateStr(date_to_convert_str: string): Date{
  var date_to_convert = new Date(date_to_convert_str);
  var local_date = new Date();

  date_to_convert.setHours(date_to_convert.getHours() + (local_date.getTimezoneOffset() / 60));
  return date_to_convert;
}

function getWorkdaysInMonth(year:number, month:number, holidays: string[]) {
  const numDays = new Date(year, month + 1, 0).getDate();
  let numWorkdays = 0;

  for (let i = 1; i <= numDays; i++) {
    const date = new Date(year, month, i);
    if (date.getDay() !== 0 && date.getDay() !== 6 && !holidays.includes(date.toISOString().slice(0,10))) {
      numWorkdays++;
    }
  }

  return numWorkdays;
}

const reverseDateString = (dateString: string) => {
  const dateSeparator = '-'

  return dateString.split(dateSeparator).reverse().join(dateSeparator);
}

export const createDateObject = (dateString: string) => {

  return new Date(dateString);
}

export const useChartData = (projectId: string = '4', executionId: string = 'b0fb7eca-dd91-4d2d-865d-17d06f80f9c1') => {
  const [tasks, setTasks] = React.useState<Task[]>([]);
  const [searchParams] = useSearchParams();
  const dataSizeString = searchParams.get('dataSize');
  const json = getDataByUrl(dataSizeString);
  const { chartTasks } = json.Chart[0];

  const getData = (charts: any) => {

    const tasks = charts.map((task: any, i: number) => {
      const start = createDateObject(task.minimalStartDate);
      const end = createDateObject(task.maximalEndDate);
      const dependencies = task.parentIDs.map((parentId: number) => +parentId);


      return {
        start,
        end,
        name: task.taskName,
        id: +task.taskID,
        dependencies,
        type: "task" as TaskType,
        displayOrder: i + 1,
        isDisabled: true,
        days: {
          startDates: task.startDates,
          endDates: task.endDates,
          start: createDateObject(task.minimalStartDate),
          end: createDateObject(task.maximalEndDate),
          uniqueStartDatesCount: new Set(task.startDates),
        },
        isLast: false
      }
    });

    const sortedTasks = tasks.sort((a: any, b: any) => a.id - b.id);
    sortedTasks[sortedTasks.length - 1].isLast = true
    // const testElement =  {
    //   start: new Date("01-27-2023 08:00:00"),
    //   end: new Date("01-29-2023 00:00:00"),
    //   name: "",
    //   id: 8,
    //   type: "task" as TaskType,
    //   displayOrder: 7 + 1,
    //   isDisabled: true,
    //   dependencies: [],
    //   days: {
    //     startDates: ["01-24-2023 01:00:00"],
    //     endDates: ["01-29-2023 08:00:00"],
    //     start: new Date("01-27-2023 08:00:00"),
    //     end: new Date("01-29-2023 00:00:00"),
    //     uniqueStartDatesCount: new Set(["01-27-2023 08:00:00"]),
    //   },
    //   isLast: true
    // }
    // sortedTasks.push(testElement)
    // const sortedTasks = tasks.sort((a: Task, b: Task) => a.id - b.id);

    setTasks(sortedTasks)
  };

  const getServerData = async () => {
    try {
      const data = await $api.get<any>(`reports?projectId=${projectId}&executionId=${executionId}&reportNames=Gantt`)
      const charts = data.data[0].ReportContent.Chart[0].chartTasks
      getData(charts)
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    // getData(chartTasks);
    getServerData()
  }, []);

  return { tasks };
}
