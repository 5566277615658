import { GanttProps, ViewMode } from "./public-types";

const TASK_SIZE = 30;
export const MINIMUM_CELL_OFFSET = 6;

export type GanttUIConfig = Omit<Omit<GanttProps, "tasks">, "maxDate">
export const baseConfig: GanttUIConfig = {
  barFill: 50,
  rowHeight: TASK_SIZE,
  removeTopMargins: true,
  arrowColor: "grey",
  //barFillBaseRBGColor: "0,0,0",
 // barStrokeColor: "grey"
}

export const getColumnWidth = (viewMode: ViewMode): number => {
  const DEFAULT_COLUMN_SIZE = 35;

  switch (viewMode) {
    case ViewMode.Year:
      return 350;

    case ViewMode.Month:
      return 300;

    case ViewMode.Week:
      return 250;

    default:
      return DEFAULT_COLUMN_SIZE;
  }
}

export const getGanttUIConfig = (viewMode: ViewMode):GanttUIConfig => {
  const columnWidth = getColumnWidth(viewMode);

  return {
    ...baseConfig,
    columnWidth,
  }
}
